<template>  
    <h3 class="text-4xl font-bold mb-5 pixel-font text-center h-16 underline">Experienced In</h3>   
        <div>
            <swiper-container 
                class="h-48 w-4/5" 
                slides-per-view="4" 
                speed="500" 
                loop="true" 
                css-mode="true"
                :autoplay="{
                    delay: 2500,
                    disableOnInteraction: false
                }"> 
                <swiper-slide>      
                    <a href="https://vuejs.org/"><img class="slide-img m-auto" :src="require(`@/assets/vue.png`)" :alt="vue"></a>   
                </swiper-slide>
                <swiper-slide class="">     
                    <a href="https://angular.io/"><img class="slide-img m-auto" :src="require(`@/assets/angular.png`)" :alt="angular"></a>
                </swiper-slide>
                <swiper-slide class="">     
                    <a href="https://react.dev/"><img class="slide-img m-auto" :src="require(`@/assets/react.png`)" :alt="react"></a>   
                </swiper-slide> 
                <swiper-slide class="">     
                    <a href="https://tailwindcss.com/"><img class="slide-img m-auto" :src="require(`@/assets/tailwind.png`)" :alt="tailwind"></a>
                </swiper-slide>
                <swiper-slide class="">     
                    <a href="https://getbootstrap.com/"><img class="slide-img m-auto" :src="require(`@/assets/bootstrap.png`)" :alt="bootstrap"></a>
                </swiper-slide>
                <swiper-slide class="">     
                    <a href="https://en.wikipedia.org/wiki/C_(programming_language)"><img class="slide-img m-auto" :src="require(`@/assets/c.png`)" :alt="c"></a>
                </swiper-slide>
                <swiper-slide class="">     
                    <a href="https://dev.java/"><img class="slide-img-java m-auto" :src="require(`@/assets/java.png`)" :alt="java"></a> 
                </swiper-slide>
                <swiper-slide class="">     
                    <a href="https://spring.io/"><img class="slide-img m-auto" :src="require(`@/assets/spring.png`)" :alt="spring"></a>
                </swiper-slide>
                <swiper-slide class="">
                    <a href="https://www.python.org/"><img class="slide-img-py m-auto" :src="require(`@/assets/python.png`)" :alt="python"></a>      
                </swiper-slide>
                <swiper-slide class="">
                    <a href="https://www.postgresql.org/"><img class="slide-img m-auto" :src="require(`@/assets/postgresql.png`)" :alt="postgresql"></a>    
                </swiper-slide>
                <swiper-slide class="">
                    <a href="https://www.mysql.com/"><img class="slide-img m-auto" :src="require(`@/assets/mysql.png`)" :alt="mysql"></a>   
                </swiper-slide>
                <swiper-slide class="">
                    <a href="https://aws.amazon.com/"><img class="slide-img m-auto" :src="require(`@/assets/aws.png`)" :alt="aws"></a>   
                </swiper-slide>
                <swiper-slide class="">
                    <a href="https://www.terraform.io/"><img class="slide-img-tf m-auto" :src="require(`@/assets/terraform.png`)" :alt="terraform"></a>
                </swiper-slide>
            </swiper-container>
        </div>
</template>

<script setup>
</script>