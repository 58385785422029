<template>
    <div class="pb-10 bg-gradient-to-r bg-black">
        <TopHeader></TopHeader>
        <div class="relative isolate px-6 pt-14 lg:px-8">
            <div class="mx-auto w-10/12 py-2 sm:py-48 lg:py-56">
                <div class="text-center">
                    <h1 class="h-10 top-text sm:text-5xl pixel-font">Andrew Pickard</h1>
                    <div class="h-32"></div>
                </div>
                <h3 class="h-1 sm:text-3xl pixel-font text-center">
                    <a href="./../dist/img/APResume.pdf" download class="download-link">
                        <i class="fas fa-file-download" style="font-size: 35px;"></i> <div style="display: inline-block; font-size: 25px;">Resume</div></a>
                </h3>
            </div>
        </div>
        <div class="pt-24 bg-gradient-to-r">
            <CardDeck title="Education" :deck=eduDeck />
            <div class="h-32"></div>
            <CardDeck title="Work" :deck="workDeck"/>
            <div class="h-32"></div>
            <SliderCard/>
        </div>
    </div>
</template>
    
<script setup>
    import AOS from 'aos';
    import 'aos/dist/aos.css';
    import CardDeck from './components/CardDeck.vue';
    import TopHeader from './components/TopHeader.vue';
    import SliderCard from './components/SliderCard.vue'
    import { register } from 'swiper/element/bundle';
    import '@fortawesome/fontawesome-free/css/all.css'

    register();

    AOS.init();    

    const GCC_BODY = ["Computer Science A.S.", "Fall 2018 - Fall 2019"]
    const RIT_BODY = ["Software Engineering B.S.", "Spring 2020 - Spring 2023"]
    const GNOS_BODY = ["Full Stack Software Engineer", "Since July 2023"]
    const RIT_WORK_BODY = ["Full Stack Software Engineer / Team Lead", "October 2022 - July 2023"]

    const eduDeck = [
        { filename: 'rit3.jpg', imgAlt: 'Pic of RIT', title: 'Rochester Institute of Technology', body: RIT_BODY, link: "https://www.rit.edu/"},    
        { filename: 'gcc3.jpg', imgAlt: 'Pic of GCC', title: 'Genesee Community College', body: GCC_BODY, link: "https://www.genesee.edu/"}
    ]

    const workDeck = [
        { filename: 'gnos2.png', imgAlt: 'Pic of Gnostech', title: 'Currently working at Gnostech LLC', body: GNOS_BODY, link: "https://gnostech.com/"},
        { filename: 'ritgsl.jpg', imgAlt: 'Pic of RIT', title: 'RIT Global Cyber Institute', body: RIT_WORK_BODY, link: "https://www.rit.edu/cybersecurity/"}
    ]
  
</script>